import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDzHK4MU6dLInmj098_6hrjUP6FonkU1bk",
    authDomain: "fatafat365-fa6c9.firebaseapp.com",
    projectId: "fatafat365-fa6c9",
    storageBucket: "fatafat365-fa6c9.appspot.com",
    messagingSenderId: "649777683929",
    appId: "1:649777683929:web:7778a7a9aeea2c61d48dc8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)