import React, { useEffect, useState } from 'react';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import './css/security.css';
import { getAuth, updatePassword, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';

const ProfileSecurity = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setEmail(user.email);
            } else {
                setEmail('');
            }
        });

        return () => unsubscribe();
    }, []);

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match.');
            return;
        }

        try {
            const auth = getAuth();
            const user = auth.currentUser;
            await updatePassword(user, newPassword);
            setSuccess('Password updated successfully.');
            setNewPassword('');
            setConfirmPassword('');
            setError('');
            console.log('Password updated successfully.');
        } catch (error) {
            setError('Error updating password: ' + error.message);
            console.log('Error updating password: ', error.message);
        }
    };

    const handleSendResetLink = async () => {
        try {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email);
            setSuccess('Password reset link sent to your email.');
            setError('');
            console.log('Password reset link sent.');
        } catch (error) {
            setError('Error sending password reset link: ' + error.message);
            setSuccess('');
            console.log('Error sending password reset link: ', error.message);
        }
    };

    return (
        <div>
            <NavbarTop />
            <div className="security-page-container">
                <div className="security-header-content">
                    <h2>Secure Your Account</h2>
                    <p>It's a very good practice to change passwords frequently to keep your account safe and secure. Please contact the admin if you face any problems.</p>
                </div>
                <div className="security-content">
                    <label htmlFor="newPassword" className="security-content-label">
                        <p>Enter New Password</p>
                        <input
                            type="password"
                            id="newPassword"
                            placeholder='Enter New Password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </label>
                    <label htmlFor="confirmPassword" className="security-content-label">
                        <p>Confirm New Password</p>
                        <input
                            type="password"
                            id="confirmPassword"
                            placeholder='Confirm New Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </label>
                    <button
                        className='changePasscode'
                        onClick={handleChangePassword}
                    >
                        Change Password
                    </button>
                    <div className="divider">or</div>
                    <button
                        className='sendResetLink changePasscode'
                        onClick={handleSendResetLink}
                    >
                        Send Password Reset Link to Email
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
};

export default ProfileSecurity;
