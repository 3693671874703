import React, { useEffect, useState, useRef } from 'react';
import './../components/css/table.css';
import './css/winhistory.css';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import { auth, db } from '../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const WinHistory = () => {
    const [winHistory, setWinHistory] = useState([]);
    const [filteredWinHistory, setFilteredWinHistory] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const todayRef = useRef(new Date()); // Use useRef for today's date

    useEffect(() => {
        const today = new Date(); // Gets current date and time in local timezone
        const formattedToday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        console.log('Today:', today);
        console.log('Formatted Today:', formattedToday);
        setStartDate(formattedToday);
        setEndDate(formattedToday);

        const fetchWinHistory = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('User not logged in');
                }
                const q = query(collection(db, 'userProfile'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    throw new Error('User profile not found');
                }

                const userProfileData = querySnapshot.docs[0].data();
                const history = userProfileData.winHistory || [];
                const winHistory = history.filter(entry => entry.status === "win");
                const reversedWinHistory = winHistory.reverse();
                setWinHistory(reversedWinHistory);

                // Filter wins for today
                const filtered = reversedWinHistory.filter(win => {
                    const winDate = new Date(win.date.split('/').reverse().join('-'));
                    return winDate.toDateString() === today.toDateString();
                });
                setFilteredWinHistory(filtered);
            } catch (error) {
                console.error('Error fetching win history:', error);
            }
        };

        if (auth.currentUser) {
            fetchWinHistory();
        }
    }, []);

    const calculateProfit = (win) => {
        const numberLength = win.number.toString().length;
        if (numberLength === 3) {
            return parseFloat(win.amount) * 100;
        } else if (numberLength === 1) {
            return parseFloat(win.amount) * 9;
        }
        return 0;
    };

    const handleSearch = () => {
        if (!startDate || !endDate) {
            alert('Please enter both start and end dates.');
            return;
        }

        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);

        const filtered = winHistory.filter(win => {
            const winDate = new Date(win.date.split('/').reverse().join('-')); // Convert win date to YYYY-MM-DD
            return winDate >= start && winDate <= end;
        });

        setFilteredWinHistory(filtered.length > 0 ? filtered : []);
    };

    const handleClear = () => {
        const todayDate = todayRef.current; // Get today's date from ref
        const formattedToday = todayDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
        setStartDate(formattedToday);
        setEndDate(formattedToday);
        // Filter wins for today
        const filtered = winHistory.filter(win => {
            const winDate = new Date(win.date.split('/').reverse().join('-'));
            return winDate.toDateString() === todayDate.toDateString();
        });
        setFilteredWinHistory(filtered);
    };

    return (
        <div>
            <NavbarTop />
            <div className="win-history-container">
                <div className="win-history-head-container">
                    <h2>Search Win History</h2>
                    <div className="search-inputs">
                        <label htmlFor="">
                            <p>Start Date</p>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                placeholder='Enter Date'
                            />
                        </label>
                        <label htmlFor="">
                            <p>End Date</p>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                placeholder='Enter Date'
                            />
                        </label>
                    </div>
                    <div className="search-buttons">
                        <button id='search-win-results' onClick={handleSearch}>Search Results</button>
                        <button id='clear-win-results' onClick={handleClear}>Clear</button>
                    </div>
                </div>
                <div className="investmentData">
                    <div className="totalPlayedAmount">
                        <b>Total Played Amount: </b> {filteredWinHistory.reduce((total, win) => total + parseFloat(win.amount), 0)}
                    </div>
                    <div className="totalWon">
                        <b>Total Won:</b> {filteredWinHistory.reduce((total, win) => win.status === 'win' ? total + calculateProfit(win) : total, 0)}
                    </div>
                </div>
                <div className="win-history-table">
                    <div className="table-wrapper">
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Game</th>
                                    <th>Date</th>
                                    <th>Number</th>
                                    <th>Play</th>
                                    <th>Won</th>
                                    <th>P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredWinHistory.length > 0 ? (
                                    filteredWinHistory.map((win, index) => {
                                        const profit = calculateProfit(win);
                                        const pl = profit - parseFloat(win.amount);
                                        return (
                                            <tr key={index}>
                                                <td>Bazi {win.gameName}</td>
                                                <td>{win.date} {win.time}</td>
                                                <td>{win.number}</td>
                                                <td>{win.amount}</td>
                                                <td>{profit}</td>
                                                <td>{pl}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="6">NO WIN HISTORY</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
}

export default WinHistory;
