import React, { useEffect, useState } from 'react';
import NavbarTop from '../navbar/navbarTop';
import { auth, db } from '../config/firebase';
import NavbarBottom from '../navbar/navbarBottom';
import './css/fund.css';
import { Link } from 'react-router-dom';
import { query, getDocs, collection, where } from 'firebase/firestore';

const AccountStatement = () => {
    const [walletBalance, setWalletBalance] = useState('-');
    const [combinedHistory, setCombinedHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const q = query(collection(db, "userProfile"), where("emailId", "==", auth.currentUser.email));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.log("No such document!");
                } else {
                    querySnapshot.forEach((doc) => {
                        console.log(doc.id, " => ", doc.data());
                        setWalletBalance(doc.data().wallet);

                        const fundAddHistory = (doc.data().fundAddHistory || []).filter(item => item.status === 'approved');
                        const fundWithdrawHistory = (doc.data().fundWithdrawHistory || []).filter(item => item.status === 'approved');

                        const combinedHistory = [
                            ...fundAddHistory.map(item => ({ ...item, type: 'Add' })),
                            ...fundWithdrawHistory.map(item => ({ ...item, type: 'Withdraw' }))
                        ];

                        combinedHistory.sort((a, b) => new Date(a.date + ' ' + a.time) - new Date(b.date + ' ' + b.time));

                        setCombinedHistory(combinedHistory);
                        setIsLoading(false);
                    });
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        if (auth.currentUser) {
            fetchUserProfile();
        } else {
            console.log("No authenticated user.");
        }
    }, []);

    return (
        <div>
            <NavbarTop />
            <div className="addFundPage-container">
                <div className="addFund-content">
                    <div className="content-withdraw-page--fund-show">
                        <div className="balance-account--withdraw-fund">
                            <div className="wallet-balance--01">
                                <h3>Wallet Balance</h3>
                                <p>₹{walletBalance}</p>
                            </div>
                            <Link>
                                <button className="addFund-01">
                                    Add Fund
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="win-history-table">
                        <div className="table-wrapper">
                            <table className="fl-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Time</th>
                                        <th>Type</th>
                                    </tr>
                                    {isLoading ? null : combinedHistory.length === 0 ? (
                                        <tr>
                                            <td colSpan="4">No data found</td>
                                        </tr>
                                    ) : null}
                                </thead>
                                <tbody>
                                    {combinedHistory.map((historyItem, index) => (
                                        <tr key={index} className={historyItem.type === 'Withdraw' ? 'inTable-Yellow-Color' : ''}>
                                            <td>{historyItem.date}</td>
                                            <td>{historyItem.amount}</td>
                                            <td>{historyItem.time}</td>
                                            <td>{historyItem.type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
}

export default AccountStatement;
