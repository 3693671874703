import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from '../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Preloader from '../components/preloader';

const ProtectedRoute = () => {
    const [isAuthenticated, setAuthenticated] = useState(null); // Initial state set to null

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {

            if (user) {
                // User is authenticated
                setAuthenticated(true);
            } else {
                // User is not authenticated
                setAuthenticated(false);
            }
        });
        // Clean up the subscription
        return () => unsubscribe();
    }, []); // Empty dependency array to ensure this effect runs only once

    if (isAuthenticated === null) {
        // Still determining authentication state
        return <Preloader />;
    } else if (isAuthenticated) {
        // User is authenticated
        console.log('User is authenticated:', isAuthenticated);
        return <Outlet />;
    } else {
        // User is not authenticated
        console.log('User is not authenticated');
        return <Navigate to="/welcome" />;
    }
};

export default ProtectedRoute;
